<template>
  <div>
    <Report module="Locação" name="Locação por Pagamento" urlGet="/api/v1/rental/report/rent-payment" :header="header"
      :filters="filters" />
  </div>
</template>

<script>

import Report from "@nixweb/nixloc-ui/src/component/report/Report";

export default {
  name: "ReportRentByCustomer",
  components: {
    Report
  },
  data() {
    return {
      header: [
        {
          title: "Dt Cadastro",
          field: "registeredDateStr",
          fieldOrderBy: "RegisteredDate",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Dt Efetivação",
          field: "dateAcceptedStr",
          fieldOrderBy: "DateAccepted",
          show: false,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Número",
          field: "number",
          fieldOrderBy: "Number",
          show: true,
          initialOrderBy: true,
          styleHeader: "width:50px;",
          styleBody: "max-width: 80px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Identificação",
          field: "identification",
          fieldOrderBy: "Identification",
          show: false,
          styleHeader: "width:140px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Consultor",
          field: "collaboratorName",
          fieldOrderBy: "CollaboratorName",
          show: false,
          styleHeader: "width:150px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Cliente",
          field: "customerName",
          fieldOrderBy: "CustomerName",
          show: true,
          styleHeader: "width:350px;",
          styleBody: "max-width: 340px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "Contato",
          field: "contactName",
          fieldOrderBy: "ContactName",
          show: false,
          styleHeader: "width:150px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "St Locação",
          field: "statusRentalName",
          fieldOrderBy: "StatusRentalName",
          show: true,
          styleHeader: "width:90px;",
          styleBody: "max-width: 80px;",
        },
        {
          title: "St Pagamento",
          field: "statusPayableReceivableName",
          fieldOrderBy: "StatusPayableReceivableName",
          show: true,
          styleHeader: "width:110px;",
          styleBody: "max-width: 140px;",
        },
        {
          title: "Vencimento",
          field: "dueDateStr",
          fieldOrderBy: "DueDate",
          show: true,
          styleHeader: "width:80px;",
          styleBody: "max-width: 50px;",
        },
        {
          title: "Tipo de Locação",
          field: "typeRentName",
          fieldOrderBy: "TypeRentName",
          show: false,
          styleHeader: "width:130px;",
          styleBody: "max-width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;",
        },
        {
          title: "R$ Valor",
          field: "valueStr",
          fieldOrderBy: "Value",
          show: true,
          styleHeader: "width:120px;",
          styleBody: "max-width: 120px;",
        },
      ],
      filters: [
        {
          type: "date",
          title: "Dt Cadastro",
          field: "RegisteredDate",
          value: "",
          required: true,
        },
        {
          type: "date",
          title: "Dt Efetivação",
          field: "DateAccepted",
          value: "",
          required: true,
        },
        {
          type: "multiOptions",
          title: "Consultor",
          field: "CollaboratorName",
          url: "/api/v1/human-resources/collaborator/select-all",
          propsParams: { isConsultant: true },
          value: "",
        },
        {
          type: "multiOptions",
          title: "Cliente",
          field: "CustomerName",
          url: "/api/v1/crm/customer/select-all-without-document",
          propsParams: { isCustomer: true, isSupplier: false },
          value: "",
        },
        {
          type: "options",
          title: "St Locação",
          field: "StatusRentalName",
          options: [
            { text: "Orçamento", value: "Orçamento" },
            { text: "Aprovada", value: "Aprovada" },
            { text: "Reprovada", value: "Reprovada" },

          ],
          value: [],
        },
        {
          type: "options",
          title: "St Pagamento",
          field: "StatusPayableReceivableName",
          options: [
            { text: "Pago", value: "Pago" },
            { text: "Recebido", value: "Recebido" },
            { text: "Hoje", value: "Hoje" },
            { text: "Pendente", value: "Pendente" },
            { text: "Agendada", value: "Agendada" },
            { text: "Sem Vínculo", value: "Sem Vínculo" },
          ],
          value: [],
        },
        {
          type: "date",
          title: "Vencimento",
          field: "DueDate",
          value: "",
          required: true,
        },
        {
          type: "multiOptions",
          title: "Tipo de Locação",
          field: "TypeRentName",
          url: "/api/v1/rental/type-rent/select-all",
          value: "",
        },
      ],
    }
  }
}


</script>